import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { BASE_URL, BASE_NODE_URL, COMMON_API_BASE_URL } from "./globle_constant";

// Utility to manage cancel tokens
const cancelTokenAllSources = [];
const cancelTokenSources = [];

export const cancelBundelListRequests = () => {
  cancelTokenSources.forEach(source => source.cancel('Operation canceled by the user.'));
  cancelTokenSources.length = 0; // Clear the array after canceling
};

export const cancelAllRequests = () => {
    cancelTokenAllSources.forEach(source => source.cancel('Operation canceled by the user.'));
    cancelTokenAllSources.length = 0; // Clear the array after canceling
};

const generateSecureString = () => {

    let storedServerTime = localStorage.getItem("curr_time_lt");
    let fetchTimestamp = localStorage.getItem("letCurrPcTime");
    let serverTime = 0;
    if (storedServerTime && fetchTimestamp) {
        let elapsed = Date.now() - Number(fetchTimestamp); // Time elapsed since fetching server time
        serverTime = Number(storedServerTime) + (Number(elapsed)/1000); // Calculate current server time
    }

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
  
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }

    let otherstr = `pevin_${result}_sitaramlaxamrekha_${serverTime}_letsecure`;
    const key256C  = CryptoJS.SHA256('infkrofkfnifkjjkfdfdrtyuiiuh').toString().substring(0, 64);
    const key128C  = CryptoJS.SHA256('qwdfgnefghnmcvbnjknmasdsdfhj').toString().substring(0, 32);
    const key = CryptoJS.enc.Hex.parse(key256C);
    const iv =  CryptoJS.enc.Hex.parse(key128C);
    const vEncrypted = CryptoJS.AES.encrypt(otherstr, key, { iv: iv }).toString();

    return vEncrypted;
}

const convertEncryptString = (formData) => {

    const key256C  = CryptoJS.SHA256('jmhnfggfdgerjhjmbdffdfmiuwqcv').toString().substring(0, 64);
    const key128C  = CryptoJS.SHA256('kjnvjkdffjdsklffdklshyoywjmdi').toString().substring(0, 32);
    const key = CryptoJS.enc.Hex.parse(key256C);
    const iv =  CryptoJS.enc.Hex.parse(key128C);

    const vEncrypted = CryptoJS.AES.encrypt(formData, key, { iv: iv }).toString();

    return vEncrypted;

}


export const convertEncryptFileString = async (file) => {

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const fileData = reader.result;

          // Generate keys using CryptoJS.SHA256
          const key256C = CryptoJS.SHA256('jmhnfggfdgerjhjmbdffdfmiuwqcv').toString().substring(0, 64);
          const key128C = CryptoJS.SHA256('kjnvjkdffjdsklffdklshyoywjmdi').toString().substring(0, 32);

          const key = CryptoJS.enc.Hex.parse(key256C);
          const iv = CryptoJS.enc.Hex.parse(key128C);

          // Encrypt file data
          const wordArray = CryptoJS.lib.WordArray.create(fileData);
          const encrypted = CryptoJS.AES.encrypt(wordArray, key, { iv: iv }).toString();

          // Create encrypted Blob
          const encryptedBlob = new Blob([encrypted], { type: file.type });
          resolve(encryptedBlob);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
}


export const convertDecryptString = (encryptedData) => {
    // Generate the key and IV using the same logic as encryption
    const key256C = CryptoJS.SHA256("jmhnfggfdgerjhjmbdffdfmiuwqcv").toString().substring(0, 64);
    const key128C = CryptoJS.SHA256("kjnvjkdffjdsklffdklshyoywjmdi").toString().substring(0, 32);
  
    const key = CryptoJS.enc.Hex.parse(key256C);
    const iv = CryptoJS.enc.Hex.parse(key128C);
  
    // Decrypt the data
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
    const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
  
    return decryptedText;
};

export const axiosRequest = (method, path, data={}, other={}) => {

    return new Promise(async(resolve, reject) => {

        let token   = (other.token) ? other.token : false;
        let node    = (other.node) ? other.node : false;
        let common    = (other.common) ? other.common : false;
        let pushAxios    = (other.cancel) ? other.cancel : false;
        let serverTime    = (other.serverTime) ? other.serverTime : 0;
        let filesData    = (other.files) ? other.files : "";
        
        const apiurl = (node) ? BASE_NODE_URL : ((common) ? COMMON_API_BASE_URL : BASE_URL);
        const url = `${apiurl}${path}`;

        let headers = {
            /* 'Content-Type': 'application/json' */
            'Content-Type': 'multipart/form-data'
        };

        //if(token){
            let csrfTokenKey = generateSecureString(serverTime);
            if(csrfTokenKey){
                headers['Authorization'] = csrfTokenKey;
            }
        //}

        if(node || common){
            headers['Content-Type'] = 'application/json';
        }

        let is_logged = localStorage.getItem("is_logged");
        let login_type = localStorage.getItem("login_type");

        if(String(is_logged) === "true"){
            let login_name = localStorage.getItem("login_name");
            let login_data = localStorage.getItem("login_data");

            if(data instanceof FormData){
                data.append("is_logged", is_logged);
                if(login_name && login_type && login_data){
                    data.append("login_name", login_name);
                    data.append("login_type", login_type);
                    data.append("login_data", login_data);
                }
            }else{
                data['is_logged'] = is_logged;
                if(login_name && login_type && login_data){
                    data['login_name'] = login_name;
                    data['login_type'] = login_type;
                    data['login_data'] = login_data;
                }

            }
        }else{
            let partner_name = localStorage.getItem("partner_name");
            let partner_code = localStorage.getItem("partner_code");
            if(data instanceof FormData){
                data.append("is_logged", false);
                if(partner_name){
                    data.append("partner_name", partner_name);
                }

                if(login_type){
                    data.append("login_type", login_type);
                }

                if(partner_code){
                    data.append("partner_code", partner_code);
                }

            }else{
                data['is_logged'] = false; 

                if(partner_name){
                    data['partner_name'] = partner_name;
                }

                if(login_type){
                    data['login_type'] = login_type;
                }

                if(partner_code){
                    data['partner_code'] = partner_code;
                }

            }
        }

        if(data instanceof FormData){
            data.append("platform_new", "web");
        }else{
            data['platform_new'] = "web";
        }

        /* if (csrfToken) {
            headers['X-CSRF-Token'] = csrfToken;
          } */

       /*  if(csrfToken && method.toUpperCase() == "POST"){
            headers['X-CSRF-Token'] = csrfToken;
        } */

        const source = axios.CancelToken.source();
        if (pushAxios) {
            cancelTokenSources.push(source);
        }

        if(path != "utc-date-time"){
            cancelTokenAllSources.push(source);
        }

        let formData = new FormData();
        let payloads = convertEncryptString(JSON.stringify(data));
        formData.append('payloads',payloads);

        if(data?.path){
            formData.append('path',data?.path);
        }

        if(data?.SendToNodeFileName){
            formData.append('SendToNodeFileName',data?.SendToNodeFileName);
        }

        if(filesData){
            for (const [key, value] of Object.entries(filesData)) {
                if(value instanceof File){
                    let encodedValue = await convertEncryptFileString(value);
                    formData.append(key,encodedValue,value?.name);
                }else if(Array.isArray(value)){
                    for (let i = 0; i < value.length; i++) {
                        const fileEle = value[i];
                        let encodedValue = await convertEncryptFileString(fileEle);
                        formData.append(key+"[]",encodedValue,fileEle?.name);
                    }
                }
            }
        }
 
        axios({
            method: method,
            url: url,
            headers: headers,
            data: formData,
            cancelToken: source.token
        }).then(response => {
            if (typeof response.data[0] != "undefined" && response.data[0].status === 200 && typeof response.data[0].body != "undefined") {
                resolve(response.data);
            }else if(response.data?.response){
                const responseData = convertDecryptString(response.data?.response);
                resolve(JSON.parse(responseData));
            }else{
                reject("somthing went wrong"); 
            }
        }).catch(error => {
            if (axios.isCancel(error)) {
                //console.log('Request canceled', error.message);
                reject(error);
            }else{
                reject(error); // Reject with the error
            }
        });
    });
};


export const axiosFullUrlRequest = (method, url, data={},other={}) => {
    return new Promise((resolve, reject) => {

        let json    = (other.json) ? other.json : false;

        let headers = {
            /* 'Content-Type': 'application/json' */
            'Content-Type': 'multipart/form-data' 
        };

        if(json){
            headers['Content-Type'] = 'application/json';
        }

        let payloads = convertEncryptString(JSON.stringify(data));
        let allFormPayload = { payloads : payloads};

        axios({
            method: method,
            url: url,
            headers: headers,
            data: allFormPayload
        }).then(response => {
            if(response.data?.response){
                const responseData = convertDecryptString(response.data?.response);
                resolve(JSON.parse(responseData)); // Resolve with the response data
            }else{
                reject("somthing went wrong"); 
            }
        }).catch(error => {
            reject(error); // Reject with the error
        });
    });
};


export const axiosNodeRequest = (method, url, token=false) => {
    return new Promise((resolve, reject) => {

        const headers = {
            /* 'Content-Type': 'application/json' */
            'Content-Type': 'multipart/form-data'
        };

        if(token){
            headers['Authorization'] = 'Bearer your_token';
        }

        axios({
            method: method,
            url: url,
            headers: headers
        }).then(response => {
            if(response.data?.response){
                const responseData = convertDecryptString(response.data?.response);
                resolve(JSON.parse(responseData)); // Resolve with the response data
            }else{
                reject("somthing went wrong");  
            }
        }).catch(error => {
            reject(error); // Reject with the error
        });
    });
};
