import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <>
            <footer className="main_footer" role="contentinfo">
                <div className="container quote_hide_footer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer_menu_row">
                                <div className="footer_menu_item">
                                    <ul>
                                        <li><Link to={"/"}>Car Insurance</Link></li>
                                        <li><Link to={"/"}>Health Insurance</Link></li>
                                        <li><Link to={"/"}>Term Insurance</Link></li>
                                        <li><Link to={"/"}>Fire Insurance</Link></li>
                                        <li><Link to={"/"}>Liability Insurance</Link></li>
                                        <li><Link to={"/"}>Marine Insurance</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_full_black">
                    <div className="container quote_hide_footer">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="get_in_touch">
                                    <div className="get_in_touch_title">Get In Touch</div>
                                    <div className="get_in_touch_row">
                                        <div className="address_row">
                                            <span><i className="fa-solid fa-location-dot"></i></span>
                                            <p>
                                                <b>Head Office</b>
                                                <small>4th floor, Shop no 74A, Patil Arcade, Kashibai Khilare Path, Erandwane, Pune, Maharashtra, 411004</small>
                                            </p>
                                        </div>
                                        <div className="contact_social">
                                            <div className="contact_details">
                                                <Link to={"tel:+91 9503642700"}><i className="fa-solid fa-phone"></i>+91 9503642700</Link>
                                                <Link to={"tel:+91 9960797033"}><i className="fa-solid fa-phone"></i>+91 9960797033</Link>
                                                <Link to={"mailto:info@letsecure.in"}><i className="fa-solid fa-envelope"></i>info@letsecure.in</Link>
                                            </div>
                                            <div className="social_media">
                                                <h3>Follow Us</h3>
                                                <span>
                                                    <Link to={"https://www.facebook.com/people/Lets-Secure/61554569915926/?mibextid=ZbWKwL"} title="facebook" rel="noopener noreferrer nofollow" target="_blank"><i className="fa-brands fa-facebook"></i></Link>
                                                    <Link to={"https://www.instagram.com/lets.secure?igsh=MTM5eWo5cno2OW84ZQ=="} title="instagram" rel="noopener noreferrer nofollow" target="_blank"><i className="fa-brands fa-instagram"></i></Link>
                                                    <Link to={"https://www.linkedin.com/company/letssecure/"} title="linkedin" rel="noopener noreferrer nofollow" target="_blank"><i className="fa-brands fa-linkedin-in"></i></Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                    <div className="footer_menu_row2">
                                <ul>
                                    <li><Link to={"/about-us"}>About Us</Link></li>
                                    <li><Link to={"/"}>Products</Link></li>
                                    <li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={"/grievance-redress-policy"}>Grievance Redress Policy</Link></li>
                                    <li><Link to={"/"}>Terms & Conditions</Link></li>
                                    <li><Link to={"/license"}>License</Link></li>
                                    <li><Link to={"/"}>ISNP</Link></li>
                                    <li><Link to={"/contact-us"}>Contact Us</Link></li>
                                </ul>
                            </div>
                    <div className="copyright_block">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>LET’S SECURE INSURANCE BROKERS PRIVATE LIMITED | Reg. Office - S.No 19, Patil Arcade, Erandawane, Pune 411004 | IRDAI Direct Insurance Broker (Life & General) | License No.- 913 | Valid From - 05/09/2023 to Valid Till - 04/09/2026 Code No. -IRDAI/DB911/2021 | CIN No - U66000PN2020PTC196618 | GST No - 27AAECL4462G1ZH | T&C Apply* Copyright © LET’S SECURE INSURANCE BROKERS PRIVATE LIMITED. All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </footer >
        </>
    );
};

export default Footer;
