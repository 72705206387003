import React, { Suspense,useEffect,useState } from 'react';
import { authSuccess } from './store/auth/action';
import { useDispatch } from 'react-redux';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { allRoute } from './util/routes';
import { ToastContainer } from "react-toastify";
import { axiosRequest } from './util/axiosRequest';
import Cookies from 'js-cookie';
import "react-toastify/dist/ReactToastify.css";
import $ from 'jquery';  // Import jQuery
const crmUrl = process.env.REACT_APP_CRM_URL;


function App() {

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [isUserLogout, setUserLogout] = useState(false);
  const [loginTypeUser, setLoginTypeUser] = useState(null);

  const checkAuthentication = () => {
    const is_logged = Cookies.get('is_logged');
    const login_type = Cookies.get('login_type');
    setLoginTypeUser(login_type);
    if (String(is_logged) === "true") {
      const login_name = Cookies.get('login_name');
      const login_data = Cookies.get('login_data');
      localStorage.setItem('is_logged', is_logged);
      localStorage.setItem('login_name', login_name);
      localStorage.setItem('login_data', login_data);
      localStorage.setItem('login_type', login_type);
      if(!login_data){
        setUserLogout(true);
      }
      dispatch(authSuccess({ is_logged, login_name, login_data, login_type }));
    } else if (String(is_logged) === "false" && login_type === "third_party") {
      
      const partner_name = Cookies.get('partner_name');
      const partner_code = Cookies.get('partner_code');
      const other_data = Cookies.get('other_data');
      const emitra_ids = Cookies.get('ids');

      if(partner_name){
        localStorage.setItem('partner_name', partner_name);
      }

      if(partner_code){
        localStorage.setItem('partner_code', partner_code);
      }

      if(other_data){
        localStorage.setItem('other_data', other_data);
      }

      if(emitra_ids){
        localStorage.setItem('ids', emitra_ids);
      }

      localStorage.removeItem("login_name");
      localStorage.removeItem("login_data");

      localStorage.setItem('is_logged', is_logged);
      localStorage.setItem('login_type', login_type);
      dispatch(authSuccess({ is_logged, login_type }));

    }else if (String(is_logged) === "false") {

      if(login_type !== "third_party"){
        localStorage.removeItem("partner_name");
        localStorage.removeItem("ids");
        localStorage.removeItem("partner_code");
        localStorage.removeItem("other_data");
      }

      localStorage.removeItem("login_name");
      localStorage.removeItem("login_data");
      localStorage.setItem('is_logged', false);
      if(login_type){
        localStorage.setItem('login_type', login_type);
        dispatch(authSuccess({ is_logged, login_type }));
      }else{
        localStorage.removeItem("login_type");
        dispatch(authSuccess({ is_logged }));
      }
    }else {
      if(typeof is_logged === "undefined" && typeof login_type === "undefined"){
        localStorage.removeItem("partner_name");
        localStorage.removeItem("ids");
        localStorage.removeItem("partner_code");
        localStorage.removeItem("other_data");
        localStorage.removeItem("login_name");
        localStorage.removeItem("login_data");
        localStorage.setItem('is_logged', false);

        if(login_type){
          localStorage.setItem('login_type', login_type);
          dispatch(authSuccess({ is_logged, login_type }));
        }else{
          localStorage.removeItem("login_type");
          dispatch(authSuccess({ is_logged }));
        }
      }
    }
  }

  const handleRedirects = async () => {

    const { pathname, search } = location;
    const searchParams = new URLSearchParams(search);

    // Handle specific paths with 'qid' parameter
    const pathsToHandle = [
      "/quotes", "/proposal", "/quote-health", "/health/review",
      "/quotes-pa", "/pa/review", "/marine-quotes", "/marine/review",
      "/life-quote", "/travel-insurance/quotes", "/travel-insurance/proposal"
    ];

    if (pathsToHandle.includes(pathname) && searchParams.has('qid')) {
      const qid = searchParams.get('qid');
      const newUrl = `${pathname}/${qid}`;
      navigate(newUrl, { replace: true });
    }

  };

  const getJwtToken = () => {

    axiosRequest("POST", "utc-date-time", {}).then((date_data) => {
      if(date_data.status){
        const serverTime = date_data?.date_time;
        let currPcTime = Date.now();
        localStorage.setItem('curr_time_lt', serverTime);
        localStorage.setItem('letCurrPcTime', currPcTime);
      }else{
        console.error(date_data?.message);
      }
    })
    .catch((error) => {
        console.log(error);
    });


    /* axiosRequest('POST', 'token')
    .then(data => {
      if(data?.status){
        Cookies.set('let_secure_token', data?.token , { expires: 1 });
      }else{
        console.error(data?.message);
      }
    })
    .catch(error => {
        console.log(error);
    }); */

  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.jQuery = $;  // Set jQuery globally if window is defined
    }

    handleRedirects();
    getJwtToken();
    

    /* const currentVersion = localStorage.getItem('appVersion');
    if (currentVersion !== APP_VERSION) {
      localStorage.clear();
      caches.keys().then(names => {
        for (let name of names) caches.delete(name);
      });
      localStorage.setItem('appVersion', APP_VERSION);
      window.location.reload(true);
    } */
   
  }, []);

  useEffect(() => {
    checkAuthentication();
  }, [location.pathname]);

  return (
    <Suspense fallback={<div></div>}>
    {isUserLogout ? <div className="main_custom_popup" id="login_popup_main_reset">
              <div className="main_custom_popup_inner">              
                  <div className="main_custom_popup_inner_body">
                      <div className="login_popup_data">
                        <p>It seems your login session is mismatched please refresh control + shift + r , if issue is still then click the <br/>
                        <a className="quote_url" href={`${crmUrl}/Logoutweb?type=${loginTypeUser}`} title="Logout">Logout</a><br/>
                         and Login again. </p>
                      </div>
                  </div>
              </div>
          </div> : 
      <Routes>
      {allRoute.map((routeData, index) => ( 
        <Route
          key={index}
          exact={routeData.exact}
          path={routeData.path}
          element={
          <routeData.layout>
            <routeData.component type={routeData?.type} tp_only={routeData?.tp_only} />
          </routeData.layout>
          }
        />
      ))}
    </Routes>}
    <ToastContainer position="top-right" autoClose={3000} />
  </Suspense>
  );
}

export default App;
